export default {
    list: {
        title: "Offers",
        class: "min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center",
        name: "affiliates",
        awayLimit: true,
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: false,
                method: true,
                link: true,
                name: "Actions",
                buttons: [
                   
                ],
            },
        },
        columns: [
            
            {
                name: "ID",
                elements: [
                    {
                        items: [
                            {
                                class: "",
                                name: "id",
                                type: "text",
                            },
                        ],
                    },
                ],
            },
            {
                name: "Offre",
                elements: [
                    {
                        items: [
                            {
                                class: "",
                                name: "name",
                                type: "text",
                            },
                        ],
                    },
                ],
            },
            {
                name: "Commission",
                class: "",
                elements: [
                    {
                        items: [
                            {
                                class: "",
                                name: "commission",
                                type: "text",
                            },
                        ],
                    },
                ],
            },
            
            {
                name: "date_added",
                class: "",
                elements: [
                    {
                        items: [
                            {
                                class: "",
                                date: true,
                                name: "createdAt",
                                type: "text",
                            },
                        ],
                    },
                ],
            },
            {
                name: "Request",
                class: "",
                elements: [{
                    items: [{
                        class: "border border-green-700 bg-green-600  hover:bg-green-800 rounded",
                        title: 'Request offre',
                        name: "request",
                        button: true,
                        img:'https://img.icons8.com/material-sharp/48/FFFFFF/parallel-tasks.png',
                        loading: true 
                    }],
                },]
            },
            {
                name: "Visibility",
                class: "",
                elements: [
                    {
                        items: [
                            {
                                class: "select-status",
                                options: ['public', 'private'],
                                name: "visibility",
                                table: "products",
                                type: "text",
                            },
                        ],
                    },
                ],
            },
           
        ],
        actions: {
            name: "actions",
            width: "10px",
            class:
                "rounded-full flex justify-center items-center focus:outline-none w-8 h-8 mr-3 mt-1",
            elements: [
                {
                    icone: "remove_red_eye",
                    color: "danger",
                    role: true,
                    key: "read",
                    link: "/affiliates/offers",
                    tooltip: "View",
                },
            ],
        },
        filters: {
            name: "products",
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "ID Offer,...",
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    },
                },
                champs: [
                    {
                        label: "Warehouses",
                        key: "warehouse",
                        name: "name",
                        value: null,
                        values: [],
                        class:
                            "py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white",
                        type: "select",
                    },
                    {
                        label: "Catégories",
                        key: "category",
                        value: null,
                        values: [],
                        class:
                            "py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white",
                        type: "select",
                    },
                    {
                        label: "date",
                        key: "Range_date",
                        value: null,
                        placeholder: "Date Range ...",
                        type: "date_range",
                        class: "py-2 text-xs px-2 outline-none block h-full w-full"
                    },
                    {
                        label: "Visibility",
                        key: "visibility",
                        value: null,
                        values: [],
                        class:
                            "py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white",
                        type: "select",
                    },
                ],
            },
        },
    },
};

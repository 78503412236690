<template>
  <div class="p-1">
    <!-- <div-table
      :config="configList"
      :data="charges"
      :idWharhouse="idWharhouse"
      :currentUser="currentUser"
      :limit="limit"
      @reset="reset"
      @refresh="refresh"
      @filtrer="filtrer"
      @search="search"
      :loading="loading"
      :paginate="paginate"
      @paginatation="paginatation"
      @Changelimit="Changelimit"
    ></div-table> -->

    <div class="">

      <div-filter :name="configList.name" :config="configList" :filters="filters" :currentUser="currentUser" :idWharhouse="idWharhouse" 
      @reset="reset" @filtrer="filtrer" @search="search" 
      :loading="loading">
    </div-filter>

    <div :class="configList.class">

     
      <div class="bg-white w-full rounded-lg shadow p-4 relative">

        <div class="flex mb-5">
            <span class="mx-4 text-2xl font-bold text-green-600">Offers</span>
            <!-- <span v-if="config.name=='statusHistories' && emailCurrentCall" class="mx-4 text-2xl semi-bold text-black-500">({{ emailCurrentCall }})</span> -->
            <div v-on-clickaway="awayLimit" v-if="configList.awayLimit">
              <!--  -->
              <button @click="openLimit = !openLimit" class="vs-con-dropdown parent-dropdown cursor-pointer">
                <div class="p-2 shadow rounded-lg  cursor-pointer flex items-end justify-center text-sm font-medium">
                  <span class="mr-2">
                    {{ paginate.currentpage || 1 }} - {{ charges.length }} / {{ paginate.total || charges.length }}
                  </span>
                  <span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px"
                      height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down h-4 w-4">
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </span>
                </div>
              </button>
              <!--  -->
              <div class="relative">
                <div v-if="openLimit"
                  class="absolute focus:ring-2 focus:ring-blue-600 right-0 dropdown-action-limit w-auto mt-3 z-10 origin-top-right rounded-md shadow-lg">
                  <div class="bg-white rounded-md shadow dark-mode:bg-gray-800 dropdown-limit">
                    <a @click="Changelimit(32)"
                      class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">32</a>
                    <a @click="Changelimit(80)"
                      class="block cursor-pointer px-2  text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">80</a>
                    <a v-if="currentUser.type !== 'Seller'" @click="Changelimit(160)"
                      class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">160</a>
                    <a v-if="currentUser.type !== 'Seller'" @click="Changelimit(320)"
                      class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">320</a>
                    <a v-if="currentUser.type !== 'Seller'" @click="Changelimit(480)"
                      class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">480</a>
                    <!-- <a v-if="currentUser.type !=='Seller'" @click="Changelimit(500)" class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">500</a>  -->
                  </div>
                  <div class="vs-dropdown--limit--after"></div>
                </div>
              </div>
              <!--  -->
            </div>
            <!--  -->
          </div>

        
        <loading v-if="!isMounted" :active.sync="loading" :is-full-page="fullPage"></loading>

        <div  class="grid lg:grid-cols-4 gap-6 products-1">
          <template v-if="isMounted && loading">
            <div v-for="ind in 8" :key="ind" class="movie--isloading">
              <div class="loading-image"></div>
              <div class="loading-content">
                <div class="loading-text-container">
                  <div class="loading-main-text"></div>
                  <div class="loading-sub-text"></div>
                </div>
                <div class="loading-btn"></div>
              </div>
            </div>
          </template>

          <div v-for="(item, index) in charges" :key="index" class="productLazyload product-item scroll-trigger alo-item slick-slide slick-active lazyloaded">

            <div  :class="dataSelected.loading && dataSelected.data._id && dataSelected.data._id.toString() === item._id.toString()?'product-pr':''" class="product-collection product-inner">
                <div>
                    <div class="product-collection__image product-image js-product-images-navigation">
                        <div class="card__media"> 
                            <div class="media  secondary_image_hover">
                              <router-link :to="{ path: `/affiliates/offers/${item._id}` }" class="d-block image_product cursor-default  ratio">
                                 <img :src="item.picture"  sizes="(min-width: 1170px) 260px, (min-width: 990px) calc((100vw - 130px) / 4), (min-width: 750px) calc((100vw - 120px) / 3), calc((100vw - 35px) / 2)" :alt="item.name" class=" motion-reduce default_media" width="1000" height="1000" loading="lazy">
                              </router-link>
                            </div>
                        </div>
                        <div class="product-image__overlay-top-left "><div class="label label--sale">TOP</div></div>     
                    </div>
                    <div class="product-collection__content style_left">  
                      <div class="title-vendor">{{ item.category }}</div>       
                        <div class="product-collection__title">
                            <h2><div  class="alo-text-transform">{{ item.name }}</div></h2>
                        </div>
                        <div  class="jdgm-widget jdgm-preview-badge">
                            <div class="jdgm-prev-badge"> 
                                  <span class="jdgm-prev-badge__text"> 
                                    <span  class="flex items-center">
                                      <span v-for="(detialPr, r) in item.details" :key="r">
                                            <img :src="'https://static.dwcdn.net/css/flag-icons/flags/4x3/' + detialPr.country.toLowerCase() + '.svg'" class="w-4 h-4 rounded-full ml-2">
                                      </span>
                                    </span>  
                                  </span> 
                            </div>
                        </div>  
                        <div class="frm-price-color">
                          <div class="panel-title">
                            <div  class="accordion-toggle collapsed pl">
                              Commission
                             <div class="text">
                                <i class="fa material-icons">arrow_forward</i> 
                                <span>{{ item.details.length>0 && item.details[0].commission && currentSettings.info.currency?(item.details[0].commission/currentSettings.info.currency).toFixed():0 }} <sup>MAD</sup></span> 
                            </div> 
                            </div>
                          </div>
                          <!-- <div class="product-collection__price ">
                            Commission: 
                            <span class="price">
                              <span class="font-bold woocommerce-Price-amount amount"> 
                                    <span class="text-black" style="font-size: 19px;">≃</span> 
                                    {{ item.details.length>0 && item.details[0].commission && currentSettings.info.currency?(item.details[0].commission/currentSettings.info.currency).toFixed():0 }} <sup>MAD</sup>
                                </span>
                            </span>
                          </div> -->
                        </div>
                        <div v-if="currentUser.type === 'Seller'" class="button-cart-bottom">
                            <div class="flex alo-gap">
                                <button :disabled="dataSelected.loading" @click="GeneratePayment(item,index)" class="add-to-cart js_add_to_cart_button loidingconfirm relative">
                                  <loading v-if="dataSelected.data._id && dataSelected.data._id.toString() === item._id.toString()" :active.sync="dataSelected.loading" :is-full-page="fullPage"></loading>
                                  <img  src="https://img.icons8.com/material-sharp/48/FFFFFF/parallel-tasks.png" class="mx-2 img-storeino inline-block" style="height: 25px;"> 
                                  <span class="text">Request offre</span>
                                </button>
                            </div>
                        </div>
                        <div v-if="currentUser.type !== 'Seller'"  class="read_more">
                            <div class="public">
                              <div class="input-group-1">
                                    <select :disabled="dataSelected.loading" @change="ChangeStatus(item,index,'products')"  :style="getStyleByName(item.visibility)" class="cursor-pointer select-status h-full w-fit py-2 px-2 text-xs capitalize outline-none block bg-white" :name="item.visibility" autocomplete="type" v-model="item.visibility">
                                          <option v-for="(value, index) in ['public', 'private']" :key="index" :value="value">
                                            {{ value }}
                                          </option>
                                    </select>
                                    <span v-if="dataSelected.data._id && dataSelected.data.name && dataSelected.data._id.toString() === item._id.toString()" class="input-group-addon  loidingconfirm relative">
                                        <loading  :active.sync="dataSelected.loading" :is-full-page="fullPage"></loading>
                                    </span>
                              </div>
                            </div>
                            <div class="item-meta inner-date">
                               <div class="flex items-center product-bottons">
                                      <router-link :to="{ path: `/affiliates/offers/${item._id}` }">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" class="fill-current inline-block mx-2 text-black opacity-75   hover:text-green-500"><path d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z"></path> </svg>
                                      </router-link>
                                      <router-link v-if="$f.getAccess(currentUser.role.permissions,'affiliates','update')" :to="{ path: `/products/edit/${item._id}` }" target= '_blank' > <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" class="fill-current inline-block mx-2 text-black opacity-75   hover:text-green-500"><path d="m11.25 6c.398 0 .75.352.75.75 0 .414-.336.75-.75.75-1.505 0-7.75 0-7.75 0v12h17v-8.75c0-.414.336-.75.75-.75s.75.336.75.75v9.25c0 .621-.522 1-1 1h-18c-.48 0-1-.379-1-1v-13c0-.481.38-1 1-1zm-2.011 6.526c-1.045 3.003-1.238 3.45-1.238 3.84 0 .441.385.626.627.626.272 0 1.108-.301 3.829-1.249zm.888-.889 3.22 3.22 8.408-8.4c.163-.163.245-.377.245-.592 0-.213-.082-.427-.245-.591-.58-.578-1.458-1.457-2.039-2.036-.163-.163-.377-.245-.591-.245-.213 0-.428.082-.592.245z"></path> </svg> </router-link>
                                      <button @click="remove(item._id,index)" :disabled="dataSelected.loading" v-if="$f.getAccess(currentUser.role.permissions,'affiliates','delete')" class="relative loidingconfirm rounded-full flex justify-center items-center focus:outline-none">
                                        <loading v-if="dataSelected.data._id && dataSelected.data._id.toString() === item._id.toString()" :active.sync="dataSelected.loading" :is-full-page="fullPage"></loading>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current inline-block mx-2 text-black opacity-75   hover:text-green-500"><path d="m20.015 6.506h-16v14.423c0 .591.448 1.071 1 1.071h14c.552 0 1-.48 1-1.071 0-3.905 0-14.423 0-14.423zm-5.75 2.494c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75zm-4.5 0c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75zm-.75-5v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-16.507c-.413 0-.747-.335-.747-.747s.334-.747.747-.747zm4.5 0v-.5h-3v.5z"></path> </svg>
                                      </button>
                                </div>
                              </div> 
                          </div>
                        
                    </div>
                </div>
            </div>
          </div>

            
          <!-- <ul v-for="(item, index) in charges" :key="index" class="products product-main-4-1-4">
              <li  :class="dataSelected.loading && dataSelected.data._id && dataSelected.data._id.toString() === item._id.toString()?'product-pr':''" class="has-rating product type-product post-337 status-publish first instock product_cat-diapers product_cat-food-milk-health product_cat-kids-clothes has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                  <div class="product-outer">
                      <div class="product-inner">
                        <div class="yith-wcwl-add-to-wishlist add-to-wishlist-337  wishlist-fragment on-first-load">
                          <div class="yith-wcwl-add-button"> <span class="add_to_wishlist"><span>TOP</span></span></div>

                        </div>
                        <div class="flex-div">
                            <div class="product-header">
                              <router-link :to="{ path: `/affiliates/offers/${item._id}` }" class="woocommerce-LoopProduct-link woocommerce-loop-product__link">
                                 <img width="600" height="600" :src="item.picture" class="attachment-woocommerce_single size-woocommerce_single" alt="" sizes="(max-width: 600px) 100vw, 600px">
                              </router-link>
                            </div>
                            <div class="product-body">
                              <div class="woocommerce-LoopProduct-link woocommerce-loop-product__link">
                                <h2 class="woocommerce-loop-product__title">{{ item.name }}</h2>
                                <small  class="flex pt-1 items-center text-gray-700"><img  src="https://img.icons8.com/color-glass/48/price-tag.png" class="inf-icon mt-0 me-3 tag-img"><b  class="ml-1">{{ item.category }}</b></small>
                                <span  class="flex items-center">
                                  <small class="text-gray-700"><img src="https://img.icons8.com/external-flaticons-flat-flat-icons/64/external-africa-vacation-planning-trip-abroad-flaticons-flat-flat-icons-3.png" class="inf-icon mt-0 me-3 africimg"></small>
                                  <span v-for="(detialPr, r) in item.details" :key="r">
                                        <img :src="'https://static.dwcdn.net/css/flag-icons/flags/4x3/' + detialPr.country.toLowerCase() + '.svg'" class="w-4 h-4 rounded-full ml-2">
                                  </span>
                                </span>
                                <div v-if="currentUser.type !== 'Seller'" class="flex items-center product-bottons">
                                      <router-link :to="{ path: `/affiliates/offers/${item._id}` }">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" class="fill-current inline-block mx-2 text-black opacity-75   hover:text-green-500"><path d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z"></path> </svg>
                                      </router-link>
                                      <router-link v-if="$f.getAccess(currentUser.role.permissions,'affiliates','update')" :to="{ path: `/products/edit/${item._id}` }" target= '_blank' > <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" class="fill-current inline-block mx-2 text-black opacity-75   hover:text-green-500"><path d="m11.25 6c.398 0 .75.352.75.75 0 .414-.336.75-.75.75-1.505 0-7.75 0-7.75 0v12h17v-8.75c0-.414.336-.75.75-.75s.75.336.75.75v9.25c0 .621-.522 1-1 1h-18c-.48 0-1-.379-1-1v-13c0-.481.38-1 1-1zm-2.011 6.526c-1.045 3.003-1.238 3.45-1.238 3.84 0 .441.385.626.627.626.272 0 1.108-.301 3.829-1.249zm.888-.889 3.22 3.22 8.408-8.4c.163-.163.245-.377.245-.592 0-.213-.082-.427-.245-.591-.58-.578-1.458-1.457-2.039-2.036-.163-.163-.377-.245-.591-.245-.213 0-.428.082-.592.245z"></path> </svg> </router-link>
                                      <button @click="remove(item._id,index)" :disabled="dataSelected.loading" v-if="$f.getAccess(currentUser.role.permissions,'affiliates','delete')" class="relative loidingconfirm rounded-full flex justify-center items-center focus:outline-none">
                                        <loading v-if="dataSelected.data._id && dataSelected.data._id.toString() === item._id.toString()" :active.sync="dataSelected.loading" :is-full-page="fullPage"></loading>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current inline-block mx-2 text-black opacity-75   hover:text-green-500"><path d="m20.015 6.506h-16v14.423c0 .591.448 1.071 1 1.071h14c.552 0 1-.48 1-1.071 0-3.905 0-14.423 0-14.423zm-5.75 2.494c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75zm-4.5 0c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75zm-.75-5v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-16.507c-.413 0-.747-.335-.747-.747s.334-.747.747-.747zm4.5 0v-.5h-3v.5z"></path> </svg>
                                      </button>
                                </div>
                                <div class="price-rating">
                                  <span>
                                    
                                    <div v-if="currentUser.type !== 'Seller'" class="input-group-1">
                                        <select :disabled="dataSelected.loading" @change="ChangeStatus(item,index,'products')"  :style="getStyleByName(item.visibility)" class="cursor-pointer select-status h-full w-fit py-2 px-2 text-xs capitalize outline-none block bg-white" :name="item.visibility" autocomplete="type" v-model="item.visibility">
                                              <option v-for="(value, index) in ['public', 'private']" :key="index" :value="value">
                                                {{ value }}
                                              </option>
                                        </select>
                                        <span v-if="dataSelected.data._id && dataSelected.data.name && dataSelected.data._id.toString() === item._id.toString()" class="input-group-addon  loidingconfirm relative">
                                            <loading  :active.sync="dataSelected.loading" :is-full-page="fullPage"></loading>
                                        </span>
                                    </div>
                                    <router-link class="input-group-1" v-else :to="{ path: `/affiliates/offers/${item._id}` }">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" class="fill-current inline-block mx-2 text-black opacity-75   hover:text-green-500"><path d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z"></path> </svg>
                                    </router-link>
        

                                  </span>
                                  <span class="price-range">Commission <span class="inline-block"> <img src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-commission-influencer-marketing-flaticons-lineal-color-flat-icons-3.png" class="inf-icon mt-0 me-3 tag-img ml-1"></span>
                                    <span  class="flex items-center price">
                                        
                                        <span class="font-bold woocommerce-Price-amount amount"> 
                                          <span class="text-black" style="font-size: 19px;">≃</span> 
                                          {{ item.details.length>0 && item.details[0].commission && currentSettings.info.currencyUSD?(item.details[0].commission/currentSettings.info.currencyUSD).toFixed():0 }} <sup>$</sup>
                                        </span>
                                 
                                      </span>
                                  </span>
                                </div>
                             </div>
                          </div>
                      </div>
                      <div v-if="currentUser.type === 'Seller'"  :class="dataSelected.loading && dataSelected.data._id && dataSelected.data._id.toString() === item._id.toString()?'active-pr':''" class="product-footer">
                          <button :disabled="dataSelected.loading" @click="GeneratePayment(item,index)" class="button product_type_simple add_to_cart_button ajax_add_to_cart btn-action hover:bg-green-800 rounded border border-green-700 bg-green-600   loidingconfirm relative">
                            <loading v-if="dataSelected.data._id && dataSelected.data._id.toString() === item._id.toString()" :active.sync="dataSelected.loading" :is-full-page="fullPage"></loading>
                            <img  src="https://img.icons8.com/material-sharp/48/FFFFFF/parallel-tasks.png" class="mx-2 img-storeino inline-block" style="height: 25px;"> Request offre
                          </button>
                      </div>
                    </div>
                  </div>
               </li>
          </ul> -->
        </div>


         <!--pagination -->
      <div class="p-1" v-if="paginate.lastpage > 1">
        <div class="flex flex-col items-center my-12">
          <div-paginate :paginate="paginate" @paginatation="paginatation" />
        </div>
      </div>
      <!--Pagination -->

    </div>
    </div>

       

     
    </div>
  
  </div>
  
</template>

<script>
import config from "./config";
export default {
  props: {
        currentUser: { type: Object},
        idWharhouse: { type: Object}
  },
  data() {
    return {
      configList: config.list,
      openLimit: false,
      loading: false,
      isMounted:false,
      formData: {},
      charges: [],
      sellers: [],
      tabId:[],
      limit: 16,
      filters:{},
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
      currentSettings:{info:{currency:""}},
      dataSelected:{ loading: false, data: {} },
      fullPage:false
    };
  },
  computed: {
    warhouseSelected(){
         return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
    },
  }, 
  watch: { 
      'warhouseSelected': async function(oldVal, newVal) {

      },
      'idWharhouse': async function(oldVal, newVal) {
          this.idWharhouse=oldVal;  
          await this.reset();
         // if (this.currentUser.type != "Seller") {  await this.getSellers();this.setDataSelect("seller", this.sellers, null);}
      }
},
  async mounted() {
    console.log('currentSettings---------',this.currentSettings)
    this.isMounted=true;
    
    if (this.currentUser.type === "Seller") {
        let pos = this.configList.columns.map(function (e) {return e.name;}).indexOf('Visibility');
        if(pos!=-1)  this.configList.columns.splice(pos, 1);  
        let pos1 = this.configList.filters.elements.champs.map(function (e) {return e.key;}).indexOf('visibility');
        if(pos1!=-1)  this.configList.filters.elements.champs.splice(pos1, 1);  
    }else{
      let pos = this.configList.columns.map(function (e) {return e.name;}).indexOf('Request');
      if(pos!=-1)  this.configList.columns.splice(pos, 1);  
    }
    if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
    else this.$f.objectToQueryString({limit:this.limit})

    if(!this.filters.limit) this.filters.limit = this.limit;

    if(this.filters.name) await this.search(this.filters.name);
    else await this.getAvoirs(this.filters);

    this.setDataSelect("visibility", ['public', 'private'], null);  
    this.setDataSelect("category", this.$f.defaultCategories(), null);  
   
    this.setDataSelect("warehouse",this.currentUser.countries, null); 
    this.currentSettings=this.$store.state.settings.find(el => el.country === this.idWharhouse.country);
    
  },
  methods: {
    awayLimit() {
      this.openLimit = false;
    },
    async remove(id,index) {
      this.$confirm(this.$t('you_sure')).then(async (res) => {
        if (res) {
          this.dataSelected.data={_id:id};
          this.dataSelected.loading=true;
          const data = await this.$server.delete('products', { _id: id });
          this.dataSelected.loading=false;
          console.log('data deleted-----',data)
          if (data && data._id) {
             this.charges=this.charges.filter(el=> el._id.toString() !== data._id.toString());
             this.dataSelected.data={};
             return this.$toast.success(`Offre deleted`);
          }else return this.$toast.warning(data);
        }
      });
    },
    async getwharhouseId(){
       await this.$store.dispatch('wharhouse/getwharhouseId',{country: this.warhouseSelected,}).then((res) => {this.idWharhouse=res;})
    },
    getStyleByName(name){
      var colorsNmae=[{name:'private',style:'color:red;border: 1px solid red;'},{name:'public',style:'color:green;border: 1px solid green'},{name:'pending',style:'color:orange;border: 1px solid orange'},{name:'approved',style:'color:green;border: 1px solid green'},{name:'rejected',style:'color:red;border: 1px solid red'}];
      if(colorsNmae.find(el=> el.name === name.toLowerCase())) return colorsNmae.find(el=> el.name === name.toLowerCase()).style;
    },
    async ChangeStatus(dataToEdit,index,name) {
         console.log('dataToEdit------',dataToEdit)
         console.log('name------',name)

          this.$confirm("Est vous sûre pour faire le changement ?").then(async (res) => {
              if (res) {
                 let bodyData={_id:dataToEdit._id,visibility:dataToEdit.visibility,oldVisibility:dataToEdit.oldVisibility};
                 this.dataSelected.data=dataToEdit;
                 this.dataSelected.loading=true;
                 const data = await this.$server.update('affiliates',bodyData);
                 this.dataSelected.loading=false;
                 this.dataSelected.data={};
                 console.log('data------',data)
                 if (data && data._id){
                     this.charges[index].visibility=dataToEdit.visibility;
                     return this.$toast.success(`Updated successfully`);
                    //await this.refresh();
                 }else{
                    this.charges[index].visibility=dataToEdit.oldVisibility;
                    return this.$toast.warning(data);
                 } 
              }
        });
      
    },
    async GeneratePayment(data, index) {
      console.log('GeneratePayment data-----',data)

         this.$confirm("Êtes-vous sûr de demander cette offre ?").then(async (res) => {
                if (res) {
                    this.dataSelected.data=data;
                    this.dataSelected.loading=true;
                    console.log('data form-----',data)
                    const { content: dataAffiliate } = await this.$server.create("affiliates",data);
                    this.dataSelected.loading=false;
                    console.log('dataAffiliate---------',dataAffiliate)
                    this.dataSelected.data={};
                    if(dataAffiliate && dataAffiliate._id) this.$toast.success("Votre demande a ete envoyé avec succès");
                    else this.$toast.warning(dataAffiliate);

                    setTimeout(() => (this.loading = false), 1200);
                    await this.refresh();
                  
                }
        });

     
    },
     setDataSelect(model, data, splice) {
      for (var i = 0; i < this.configList.filters.elements.champs.length; i++) {
        if (
          this.configList.filters.elements.champs[i].type === "select" &&
          this.configList.filters.elements.champs[i].key === model
        ) {
          if (!splice) {
            this.configList.filters.elements.champs[i].values = data;
          } else {
            this.configList.filters.elements.champs.splice(i, 1);
          }
        }
      }
    },
    async getSellers() {
      const filter = {
         type: "Seller",
         //countries:this.idWharhouse.country,
         limit:10,
      };
       if (this.currentUser.type != "Seller") {
          const res = await this.$server.search("users", filter);
          if (res.content.results) this.sellers = res.content.results;
          else this.sellers = [];
       }
    },
    async Changelimit(val){
       if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
       this.filters['limit']=val;
       this.filters['page']=1;
       this.paginate.currentpage=1;
       this.$f.objectToQueryString(this.filters)
       if(this.filters.name) await this.search(this.filters.name);
       else this.getAvoirs(this.filters);
    },
    async filtrer(data) {
       if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
       delete  this.filters.page;
       delete  this.filters.name;

       console.log('filter search________',data)
       this.paginate.currentpage=1;
       if(!this.filters['limit']) this.filters['limit']=this.limit;
       if(data.warehouse)  {this.filters['details.country']=data.warehouse.code;}else delete this.filters['details.country'];
       if(data.category)  {this.filters.category=data.category;}else delete this.filters.category;
       if(data.visibility)  {this.filters.visibility=data.visibility;}else delete this.filters.visibility;
       if(data.Range_date && data.Range_date[0]){this.filters.Range_date=data.Range_date.map( el => this.$moment(el).format('yyyy-MM-DD'));}else delete this.filters.Range_date;
       this.$f.objectToQueryString(this.filters)
      await this.getAvoirs(this.filters);
    },
    /**********************************User and Status******************************************************/

   
    async reset() {
        let lmitt=this.limit;
        if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
        if(this.filters['limit']) lmitt=this.filters['limit'];
        this.filters['page']=1;
        this.$f.objectToQueryString({limit:lmitt})
        await this.getAvoirs( {limit:lmitt});
    },
    async refresh() {
        if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
        this.$f.objectToQueryString(this.filters)
        if(this.filters.name) await this.search(this.filters.name);
        else await this.getAvoirs(this.filters);
       if(this.filters['page'] && this.filters['page']>1 && this.charges.length==0){
          this.filters['page']= --this.filters['page'];
           this.$f.objectToQueryString(this.filters)
          await this.getAvoirs(this.filters);
       }
    },
    async getAvoirs(filters) {
      this.loading = true;
      filters.offers=true;
      const res = await this.$server.search("affiliates", filters);
      this.loading = false;
      this.isMounted=false;
      if (res && res.content.results) {
        this.charges = res.content.results;
        this.charges.forEach(function (offre) {
          offre.oldVisibility= offre.visibility;

          // offre.commission="<div class='offers offers-card'>";
          //   this.$f.removeCountryByTwoArray(offre.details,this.currentUser.countries).forEach(function (el) {
          //   offre.commission += `
          //   <div class="commis_affiliate">
          //     <div class="flex items-center ng-star-inserted">
          //       <span><img src="https://static.dwcdn.net/css/flag-icons/flags/4x3/${el.country.toLowerCase()}.svg" class="w-4 h-4 rounded-full mr-1 inline-block">Delivered</span>
          //       <span class="border-gray-700 inline-flex h-10 flex-grow border-b border-dashed"></span>
          //        <span class="ng-star-inserted"> <b>${(el.commission/this.currentSettings.info.currency).toFixed()} <sup>MAD</sup></b> </span>
          //     </div>
          //      <small class="flex justify-center items-center -mt10"><b class="offre-stock"><img style="width: 11px;" src="https://img.icons8.com/external-tanah-basah-basic-outline-tanah-basah/48/external-boxes-shipping-and-delivery-tanah-basah-basic-outline-tanah-basah.png" class="mr-1 inline-block">Quantity in Stock: ${el.quantity.inStock}</b></small> 
          //   </div>
          //   `;
          // }.bind(this));
          //   offre.commission += "</div>";
         
        }.bind(this));
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.charges = [];this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}
      }
    },
    async search(data) {
       this.paginate= {total: 0,currentpage: 1,lastpage: 1,per_page: 0,};
       let limitt=this.limit
       if(this.filters['limit']) limitt=this.filters['limit'];
       const search = {
        name:data.trim(),
        limit:parseInt(limitt),
        offers:true,
      }

     this.$f.objectToQueryString(search)
     this.loading = true;
     const  res = await this.$server.find("affiliates", search);
     this.loading = false;
      this.isMounted=false;
       if (res.content.results) {
        this.charges = res.content.results; 
        this.charges.forEach(function (offre) {
          offre.oldVisibility= offre.visibility;

          // offre.commission="<div class='offers offers-card'>";
          //   this.$f.removeCountryByTwoArray(offre.details,this.currentUser.countries).forEach(function (el) {
          //   offre.commission += `
          //   <div class="commis_affiliate">
          //     <div class="flex items-center ng-star-inserted">
          //       <span><img src="https://static.dwcdn.net/css/flag-icons/flags/4x3/${el.country.toLowerCase()}.svg" class="w-4 h-4 rounded-full mr-1 inline-block">Delivered</span>
          //       <span class="border-gray-700 inline-flex h-10 flex-grow border-b border-dashed"></span>
          //       <span class="ng-star-inserted"> <b>${(el.commission/this.currentSettings.info.currency).toFixed()} <sup>MAD</sup></b> </span>
          //     </div>
          //      <small class="flex justify-center items-center -mt10"><b class="offre-stock"><img style="width: 11px;" src="https://img.icons8.com/external-tanah-basah-basic-outline-tanah-basah/48/external-boxes-shipping-and-delivery-tanah-basah-basic-outline-tanah-basah.png" class="mr-1 inline-block">Quantity in Stock: ${el.quantity.inStock}</b></small> 
          //   </div>
          //   `;
          // }.bind(this));
          //   offre.commission += "</div>";
         
        }.bind(this));
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.charges = [];this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}
      }
        
    },
    /********************************************************************************************/
    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }
      if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
      this.filters['page']=page;
      this.$f.objectToQueryString(this.filters)

      this.getAvoirs(this.filters);
    },
  },
};
</script>

<style scoped>

.product:hover,.product-pr {
    box-shadow:0 7px 10px 0 rgba(23,84,116,.18)
}

 .products-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
}
ul.products.product-main-4-1-4 {
    height: calc(100% - 12px);
  }
.add_to_wishlist{
  background-color: #45b44d;
  font-weight: bold;
  color: white;
  padding: 5px;
  font-size: 12px;
  position: absolute;
  top: 0px;
  right: 0;
}
.products.product-main-4-1-4 {
    margin: 0;
    align-items: stretch;
  }
  ul.products {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-right: -6px;
  margin-left: -6px;
}
ul.products.product-main-4-1-4 > li.product {
  margin-bottom: 0;
  height: 100%;
}
.products > .product:hover,.product-pr{
    z-index: 2;
  }
.products.product-main-4-1-4 .product {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
  }
ul.products.product-main-4-1-4 > li.product {
    border-top: none;
    border-bottom: none;
  }
.product-outer {
  height: 100%;
  position: relative;
}
.product-inner {
  border: 1px solid #ddd;
  height: 100%;
  box-shadow: 0px 9px 20px rgb(17 14 27 / 10%);
  -webkit-box-shadow: 0px 9px 20px rgb(17 14 27 / 10%);
}
li.product:hover .flex-div,.product-pr .flex-div {  
    height: calc(100% - 63px);
}
.flex-div {
    display: flex;  
    flex-direction: column;
    height: 100%;
  }
.product-header {
    width: 100%;
    margin: auto;
    border-bottom: none;
    position: relative;
  }
  .yith-wcwl-add-to-wishlist {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  margin-top: 10px;
}
.woocommerce-loop-product__link {
  display: block;
  text-decoration: none;
}
.product-header img {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
 .product-body {
    padding: 20px 29px;
    margin-top: auto;
    border-top: 1px solid #ebebeb;
  }
.woocommerce-loop-product__title {
    font-size: 18px;
    line-height: 1.4;
    max-height: 55px;
    margin-bottom: 2px;
    font-weight: 300;
    height: auto;
    overflow: hidden;
    color: #252525;
    margin-top: 0;
  }
.price-rating {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
.woocommerce-review-count {
    margin-left: 8px;
    line-height: 1.25;
  }
  .woocommerce-review-count {
  font-size: 12px;
  color: #757575;
}
.price-range {
  font-size: 14px;
  color: #8f8f8f;
  display: block;
  text-align: center;
}
.price-rating .price {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.25;
  }
.price {
  display: block;
}
.woocommerce-Price-amount {
  font-weight: 500;
  color: #45b44d;
}
.products > .product:hover .product-footer{
    display: block;
}

.active-pr {
    display: block !important;
}

.product-footer {
  text-align: center;
  padding: 5px 20px 20px;
  display: none;
}
  .btn-action, .added_to_cart {
  font-weight: 400;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
  padding: 9px 10px;
  font-size: 13px;
  line-height: 18px;
  border-radius: 2px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  display: block;
  width: 100%;
  white-space: normal;
  color: #fff;
  cursor: pointer;
}


.movie--isloading {
  height: 420px;
  border-radius: 4px;
  overflow: hidden;
}

.movie--isloading .loading-image {
  height: 350px;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-size: 250px;
  -webkit-animation: shine-loading-image 2s infinite ease-out;
          animation: shine-loading-image 2s infinite ease-out;
}

.movie--isloading .loading-content {
  background: #f7f7f7;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.movie--isloading .loading-content .loading-text-container {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}

.movie--isloading .loading-content .loading-main-text {
  height: 10px;
  width: 65%;
  margin-bottom: 10px;
  background: #ececec;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
  background-size: 250px;
  border-radius: 10px;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
          animation: shine-loading-container-items 2s infinite ease-out;
}

.movie--isloading .loading-content .loading-sub-text {
  height: 10px;
  width: 50%;
  background: #ececec;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
  background-size: 250px;
  border-radius: 10px;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
          animation: shine-loading-container-items 2s infinite ease-out;
}

.movie--isloading .loading-content .loading-btn {
  width: 60px;
  height: 25px;
  background: #ececec;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
  background-size: 250px;
  border-radius: 3px;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
          animation: shine-loading-container-items 2s infinite ease-out;
}


@-webkit-keyframes shine-loading-image {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}


@keyframes shine-loading-image {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}

@-webkit-keyframes shine-loading-container-items {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}

@keyframes shine-loading-container-items {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}


.alo-item {
    box-sizing: border-box;
    min-height: 1px;
    display: block;
    height: 100%;
    min-width: 1px;
}
.product-collection {
  -webkit-transition: all .4s linear 0ms;
  -khtml-transition: all .4s linear 0ms;
  -moz-transition: all .4s linear 0ms;
  -ms-transition: all .4s linear 0ms;
  -o-transition: all .4s linear 0ms;
  transition: all .4s linear 0ms;
  position: relative;
  z-index: 1;
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  flex-direction: column;
  display: flex;
}
.product-collection__image {
  position: relative;
}
.image_product {
  position: relative;
  display: block;
  overflow: hidden;
  max-width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  line-height: 1;
  cursor: pointer;
}
.ratio::before {
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 100.0%;
  display: block;
}

.card__media .media  img {
  display: block;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  -webkit-transition: all .4s linear 0ms;
  -khtml-transition: all .4s linear 0ms;
  -moz-transition: all .4s linear 0ms;
  -ms-transition: all .4s linear 0ms;
  -o-transition: all .4s linear 0ms;
  transition: all .4s linear 0ms;
}
.product-image__overlay-top-left {
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  gap: 5px;
}

.product-image__overlay-top-left .label {
  padding: 0 5px;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 2px;
  min-width: 55px;
  height: 25px;
  font-size: 14px;
  line-height: 15px;
  background-color: #59aa18;
  color: #fff;
  box-shadow: 0 1px 2px #0003;
}

.product-collection__content {
  flex-direction: column;
  display: flex;
  position: relative;
  padding-top: 15px;
  padding-bottom: 0;
  -webkit-transition: all .4s linear 0ms;
  -khtml-transition: all .4s linear 0ms;
  -moz-transition: all .4s linear 0ms;
  -ms-transition: all .4s linear 0ms;
  -o-transition: all .4s linear 0ms;
  transition: all .4s linear 0ms;
}
.style_left {
  align-items: flex-start;
  display: flex;
  text-align: left;
  justify-content: flex-start;
}

.product-item .product-collection__title {
  margin-bottom: 0;
}

.product-item .product-collection__title h2 {
  margin-bottom: 5px;
  position: relative;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0em;
}

.alo-text-transform {
  text-transform: none;
  color: #0067c8;
  width: 100%;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}
.product-item .jdgm-widget.jdgm-widget {
  margin-bottom: 5px;
}
.jdgm-widget.jdgm-widget {
  display: block;
}
.product-item .jdgm-prev-badge__text {
  position: relative;
  font-size: 13px;
  color: #757474;
}
.jdgm-prev-badge__text {
  visibility: unset;
}

.product-item .frm-price-color .product-collection__price .price {
  color: #202020;
  text-decoration: unset;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  display: inline-block;
  float: right;
}
.jdgm-prev-badge{
  height: 15px;
}

.product-item .price .real, .product-item .price span {
  color: #202020;
  text-decoration: unset;
  line-height: 22px;
  font-weight: 500;
}
.product-item .frm-price-color {
  margin-bottom: 10px;
  width: 100%;
}

 .product-item .button-cart-bottom {
  width: 100%;

}
.alo-gap {
  gap: 5px;
}

.product-item .button-cart-bottom .select_options, .product-item .button-cart-bottom .add-to-cart {
  text-transform: capitalize;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  height: 40px;
  border-radius: 40px;
  background-color: #ff5b2d;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  display: flex;
  -webkit-transition: all .24s linear 0ms;
  -khtml-transition: all .24s linear 0ms;
  -moz-transition: all .24s linear 0ms;
  -ms-transition: all .24s linear 0ms;
  -o-transition: all .24s linear 0ms;
  transition: all .24s linear 0ms;
}

.product-item .button-cart-bottom .select_options, .product-item .button-cart-bottom .add-to-cart {
  text-transform: capitalize;
  color: #fff;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.title-vendor {
  margin-bottom: 0;
  color: #777;
  font-size: 76.307%;
  transition: .1s;
  -moz-transition: .1s;
  -webkit-transition: .1s;
  -o-transition: .1s;
  text-transform: capitalize;
}
.read_more {
  display: flex;
  padding-top: 20px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e1dfdf;
  width: 100%;
}
.read_more .public {
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}
.inner-date {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 18px;
  margin-left: 22px;
  position: relative;
}

.panel-title {
  font-size: inherit;
  font-weight: inherit;
  position: relative;
  border-radius: inherit;
  transition: all .075s ease;
  padding: 0;
  border: none;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.panel-title .pl {
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0px;
  display: flex;
  align-items: center;
}
.panel-title .text{
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;        /* for single line flex container */
  align-content: center;  
}
.panel-title i{
  font-size: 20px;
  padding-right: 5px;
}
</style>
